import { createStore } from "vuex";

export default createStore({
  state: {
    userdata: {
      realname: "",
    },
  },
  getters: {},
  mutations: {
    getdata(state, userdata) {
      state.userdata = null;
      state.userdata = userdata;
    },
  },
  actions: {
    getdata(ctx, userdata) {
      ctx.commit("getdata", userdata);
    },
  },
  modules: {},
});
