<template>
  <div class="login">
    <img :src="bg" alt="" class="bg" />
    <div class="login_form">
      <div class="login_title">项目管理系统</div>
      <el-form ref="loginForm" :model="loginForm" :rules="rules">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入账号">
            <template #prepend>
              <el-button icon="User"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="loginForm.password"
          >
            <template #prepend>
              <el-button icon="Lock"></el-button>
            </template>
          </el-input>
        </el-form-item>

        <div class="login-btn">
          <el-button type="primary" @click="submitForm(loginForm)"
            >登录</el-button
          >
        </div>
        <div class="zhuce">
          <router-link :to="{ name: 'register' }">点击注册</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import Bg from "../assets/image/sign_bj.jpg";
import request from "/utils/request.js";
import { ElMessage } from "element-plus";
export default {
  name: "",
  data() {
    return {
      bg: Bg,
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "用户名长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "密码长度在 6 到12 个字符",
            trigger: "blur",
          },
        ],
      },
      userToken: "",
    };
  },
  props: [],
  components: {},
  computed: {},
  filters: {},
  methods: {
    submitForm() {
      let that = this;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          request
            .post("api/login", this.loginForm)
            .then((res) => {
              localStorage.setItem("token", res.user.token);
              if (res.code == 200) {
                localStorage.setItem("relname", res.user.realname);
                ElMessage({
                  message: "登陆成功",
                  type: "success",
                });
                that.$router.push({ path: "/home" });

                // } else {
                //   ElMessage({
                //     message: "账号或密码错误",
                //     type: "error",
                //   });
              }
            })
            .catch((err) => {
              ElMessage({
                message: "账号或密码错误",
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.login {
  overflow: hidden;
  .bg {
    width: 100%;
    height: 943px;
  }

  .login_form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
    padding: 30px;
    text-align: center;
    .login_title {
      width: 100%;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      color: #fff;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
    }

    .el-button {
      height: 46px;

      position: relative;
    }
    .login-btn {
      button {
        width: 100%;
        height: 36px;
      }
    }
  }
  .zhuce {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>
