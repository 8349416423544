import axios from "axios";
import { ElMessage } from "element-plus";
axios.defaults.baseURL = "https://sasstemplate.dyyseo.com";

export default {
  send(options = {}) {
    var method = options.method == undefined ? "get" : options.method;
    var apiName = options.url == undefined ? "" : options.url;
    var params = options.data == undefined ? "" : options.data;
    if (apiName == "") {
      //	空请求地址 异常
    }
    if (params == "") {
      //	空请求内容 异常
    }

    // 获取访问令牌
    var token = this.getStorage("token");
    token = token == undefined ? "" : token;
    var header = null;
    if (token != null) {
      header = {
        token: token,
        "Content-Type": "application/x-www-form-urlencoded",
      };
    } else {
      header = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
    }

    //	请求
    if (method == "post") {
      return new Promise((resolve, reject) => {
        axios
          .post(apiName, { params: params, headers: header })
          .then((response) => {
            if (response.status == 200) {
              this.cuowu(response);
              //	访问成功
              // if (response.data.code == 200) {
              resolve(response.data);
              // }
            } else {
              reject(response.data);
            }
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(apiName, { params: params, headers: header })
          .then((response) => {
            if (response.status == 200) {
              this.cuowu(response);
              resolve(response.data);
            } else {
              reject(response.data);
            }
          });
      });
    }
  },
  cuowu(response) {
    //	登录超时
    if (response.data.code == 300) {
      //	弹框提示:登录超时  并清除token缓存跳转至登录页面让重新登录
      ElMessage.error("登录超时");
      localStorage.removeItem("token");
      window.location.href = "/#/";
    }
    //	未登录无权限操作

    if (response.data.code == 400) {
      //	弹框提示:未登录无权限访问  并清除token缓存跳转至登录页面让重新登录
      ElMessage.error("未登录无权限访问");
      localStorage.removeItem("token");
      window.location.href = "/#/";
    }
    //	访问出错
    if (response.data.code == 500) {
      //	弹框提示:网络出现异常,请稍后重试
      // ElMessage.error("网络出现异常,请稍后重试");
    }
  },
  getStorage(key, encrypted = false) {
    var value = window.localStorage.getItem(key);
    if (encrypted) {
      value = JSON.parse(value);
    }
    return value;
  },
  setStorage(key, value, encrypted = false) {
    if (encrypted) {
      value = JSON.stringify(value);
    }
    var result = window.localStorage.setItem(key, value);

    return result;
  },
  get(url = "", data = {}) {
    var response = this.send({
      url: url,
      data: data,
    });
    return response;
  },
  post(url = "", data = {}) {
    var response = this.send({
      url: url,
      data: data,
      method: "post",
    });
    return response;
  },
};
