<template>
  <div class="home">
    <el-container>
      <el-aside width="collapse">
        <div class="logo">
          <img :src="src" alt="" class="img" v-if="imgs" />
          <div v-else>第一页</div>
        </div>
        <el-menu
          active-text-color="#409eff"
          background-color="#545c64"
          class="el-menu-vertical-demo"
          default-active="1"
          text-color="#fff"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
        >
          <el-menu-item index="1">
            <el-icon><HomeFilled /></el-icon>
            <span
              @click="
                this.$router.push({
                  path: '/time',
                })
              "
              >首 页</span
            >
          </el-menu-item>
          <el-sub-menu index="2" v-if="$route.meta.show">
            <template #title>
              <el-icon><Menu /></el-icon>
              <span>项目详情</span>
            </template>
            <el-menu-item
              index="2-1"
              @click="
                this.$router.push({
                  path: '/project',
                  query: { id: this.$route.query.id },
                })
              "
              >立项说明</el-menu-item
            >
            <el-menu-item
              index="2-2"
              @click="
                this.$router.push({
                  path: '/meeting',
                  query: { id: this.$route.query.id },
                })
              "
              >会议记录</el-menu-item
            >
            <el-menu-item
              index="2-3"
              @click="
                this.$router.push({
                  path: '/report',
                  query: { name: 'plan', id: this.$route.query.id },
                })
              "
              >研发计划书</el-menu-item
            >
            <el-menu-item
              index="2-4"
              @click="
                this.$router.push({
                  path: '/report',
                  query: { name: 'trial', id: this.$route.query.id },
                })
              "
              >研发功能测试报告</el-menu-item
            >
            <el-menu-item
              index="2-5"
              @click="
                this.$router.push({
                  path: '/report',
                  query: { name: 'result', id: this.$route.query.id },
                })
              "
              >阶段性成功报告</el-menu-item
            >
            <el-menu-item
              index="2-6"
              @click="
                this.$router.push({
                  path: '/softness',
                  query: { id: this.$route.query.id },
                })
              "
              >软著列表</el-menu-item
            >
            <el-menu-item
              index="2-7"
              @click="
                this.$router.push({
                  path: '/summarize',
                  query: { name: 'overview', id: this.$route.query.id },
                })
              "
              >项目总结报告</el-menu-item
            >
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="collapse_icon">
            <div class="el-icon-s-fold" @click="isC" v-show="block">
              <el-icon><Fold /></el-icon>
            </div>
            <div class="el-icon-s-unfold" @click="isC" v-show="toggle">
              <el-icon><Expand /></el-icon>
            </div>
          </div>
          <div class="user">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                欢迎您:{{ getUsername }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="secretsubmit()"
                    >获取秘钥</el-dropdown-item
                  >
                  <el-dropdown-item @click="changeFrom = true"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="tuichu()"
                    class="el-dropdown-menu__item--divided"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog v-model="secretForm" title="获取秘钥" width="600px">
      <div class="secret_title">
        该秘钥只可提供给公司内部人员，切勿提供给外人
      </div>
      <div class="secret_content">
        {{ secret }}
      </div>
      <span class="copy" @click="copyToClip()">复制</span>
    </el-dialog>
    <el-dialog v-model="changeFrom" title="修改密码" width="600px">
      <el-form
        :model="changefrom"
        label-width="100px"
        :rules="rule"
        ref="changefrom"
      >
        <el-form-item prop="password" label="修改密码：">
          <el-input
            placeholder="请输入修改后的密码"
            v-model="changefrom.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="form_button">
          <el-button type="primary" @click="changePassword()">提交</el-button>
          <el-button @click="changefrom = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import request from "/utils/request.js";
const src = require("../assets/image/logo.png");
export default {
  name: "Home",
  data() {
    return {
      imgs: true,
      isCollapse: false, //导航栏默认为展开
      toggle: false, //第二个图标默认隐藏
      block: true, //默认显示第一个图标
      src,
      getUsername: "",
      secret: "",
      secretForm: false,
      changeFrom: false,
      changefrom: {
        password: "",
      },
      rule: {
        password: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到12 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    copyToClip(item) {
      let OrderNumber = this.secret;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    isC() {
      this.imgs = !this.imgs;
      this.isCollapse = !this.isCollapse;
      this.toggle = !this.toggle;
      this.block = !this.block;
    },
    secretsubmit() {
      let _this = this;
      request.get("api/secret").then((res) => {
        if (res.code == 200) {
          // 隐藏添加用户对话框
          _this.secret = res.secret;
          _this.secretForm = true;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    changePassword() {
      let _this = this;
      this.$refs.changefrom.validate((valid) => {
        if (valid) {
          request.post("api/moduser", _this.changefrom).then((res) => {
            if (res.code == 200) {
              _this.$message.success(res.msg);
              _this.changeFrom = false;
            } else {
              _this.$message.error(res.msg);
            }
          });
        }
      });
    },

    tuichu() {
      //退出登录，清空token
      ElMessage({
        message: "退出登录",
        type: "warning",
      });
      localStorage.removeItem("token");
      localStorage.removeItem("relname");
      this.$router.push({ name: "login" });
    },
  },
  created() {
    this.getUsername = localStorage.getItem("relname");
  },
};
</script>
<style lang="less" scoped>
.home {
  .el-container {
    .el-aside {
      -webkit-transition: width 0.28s;
      transition: width 0.28s;
      background-color: #545c64;
      height: 100vh;
      overflow: hidden;
      -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
      box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
      z-index: 1001;
      .logo {
        width: 100%;
        display: inline-block;
        padding: 10px;
        text-align: center;
        height: 50px;
        transition: width 0.28s;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        line-height: 50px;
        img {
          max-width: 120px;
        }
      }
      .el-menu-vertical-demo {
        margin-top: 15px;
        border: 0;
        .el-menu-item {
          font-size: 16px;
        }

        .el-dropdown-menu {
          .el-dropdown-menu__item--divided {
            border-top: 1px solid #e6ebf5;
            position: relative;
          }
        }
      }
      .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 170px;
      }
    }
    .el-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 50px 0 20px;
      height: 50px;
      overflow: hidden;
      position: relative;
      background: #fff;
      -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      .collapse_icon {
        font-size: 24px;
        line-height: 24px;
      }
      .el-dropdown-link {
        color: #5a5e66;
      }
    }
    .el-container {
      // height: calc(100vh - 60px);
      .el-menu {
        width: 200px;
        span {
          font-size: 18px;
          font-weight: bold;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .secret_title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .secret_content {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .copy {
    color: #409efe;
    font-size: 16px;
  }
  .copy:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  border: none;
  font-size: 16px;
  font-weight: bold;
  .el-icon--right {
    font-size: 20px;
  }
}
</style>
