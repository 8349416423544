import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Itme from "../views/Itme.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

// import Detailed from "../views/Detailed.vue";
import Meeting from "../views/Meeting.vue";
import Report from "../views/Report.vue";
import Project from "../views/Project.vue";
import Softness from "../views/Softness.vue";
import Summarize from "../views/Summarize.vue";
const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "register", component: Register },
  {
    path: "/home",
    name: "home",
    component: Home,
    redirect: "/time",
    children: [
      { path: "/time", name: "time", component: Itme, meta: { show: false } },
      {
        path: "/project",
        name: "project",
        component: Project,
        meta: { show: true },
      },
      {
        path: "/meeting",
        name: "meeting",
        component: Meeting,
        meta: { show: true },
      },
      {
        path: "/report",
        name: "report",
        component: Report,
        meta: { show: true },
      },
      {
        path: "/softness",
        name: "softness",
        component: Softness,
        meta: { show: true },
      },
      {
        path: "/summarize",
        name: "summarize",
        component: Summarize,
        meta: { show: true },
      },
    ],
  },
  // {
  //   path: "/detailed",
  //   name: "detailed",
  //   component: Detailed,
  //   redirect: "/project",
  //   children: [
  //     { path: "/project", name: "project", component: Project },
  //     { path: "/meeting", name: "meeting", component: Meeting },
  //     { path: "/report", name: "report", component: Report },
  //     { path: "/softness", name: "softness", component: Softness },
  //     { path: "/summarize", name: "summarize", component: Summarize },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHashHistory(),

  routes,
});
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  //如果去往登录页则放行
  if (to.path === "/" || to.path === "/register") {
    next();
  } else {
    // 从本地存储里获取token
    let token = localStorage.getItem("token");
    // 判断token是否为空如果为空则跳转到登录页 如果有则放行
    if (token === null || token === "") {
      next({ path: "/" });
    } else {
      next();
    }
  }
});
export default router;
